import * as THREE from "three";

export default class Path extends THREE.Vector3 {

  get direction() {
    if (this.startingPoint === this.link.point1) {
      return 1;
    }
    else {
      return -1;
    }
  }

  constructor(particlePool, minSpeed, maxSpeed, point, link) {
    super();
    this.minSpeed = minSpeed;
    this.maxSpeed = maxSpeed;
    this.speed = THREE.Math.randFloat(this.minSpeed, this.maxSpeed);
    this.alive = true;
    this.startingPoint = point;
    this.link = link;
    this.hasFired = false;
    this.particles = [];
    for (let i = 0; i < 4; ++i) {
      const p = particlePool.getParticle();
      if (p) {
        this.particles.push(p);
      }
    }
    this.t = 0;
  }

  travel(deltaTime) {
    this.t += this.speed * deltaTime;
    if (this.t >= 1 && !this.hasFired) {
      if (this.direction > 0) {
        this.link.point2.receivedPath = true;
        this.link.point2.prevRelease = this.link;
        //this.link.point2.firedCount = this.link.point1.firedCount + 1;
      }
      else {
        this.link.point1.receivedPath = true;
        this.link.point1.prevRelease = this.link;
        //this.link.point1.firedCount = this.link.point2.firedCount + 1;
      }
      this.hasFired = true;
    }

    for (let i = 1; i <= this.particles.length; ++i) {
      const p = this.particles[i - 1];
      if (!p) {
        continue;
      }
      let t = this.t / i;
      if (t > 1) {
        t = 1;
        if (i <= this.particles.length) {
          p.free();
          this.particles[i - 1] = null;
          continue;
        }
      }
      let pos;
      if (this.direction > 0) {
        pos = this.link.getPoint(t);
      }
      else {
        pos = this.link.getPoint(1 - t);
      }
      p.set(pos.x, pos.y, pos.z);
    }
    if (this.t / this.particles.length > 1) {
      this.alive = false;
    }
  }
}