import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import Loader from "./loader"
import ParticlePool from "./particle-pool";
import Point from "./point";

export default class Scene {

  timeSinceLastRelease = 5;
  initialized = false;
  settings = {
    points: 3000,
    radius: 100,
    maxDist: 10,
    maxConnections: 4,
    minSpeed: 3.75,
    maxSpeed: 4.25,
    currentMaxPaths: 100,
    limitPaths: 100
  };

  constructor() {
    this.init();
  }

  async init() {
    this.loader = new Loader();
    await this.loader.load();

    this.meshComponents = new THREE.Object3D();
    this.particlePool = new ParticlePool(this, this.settings.limitPaths);
    this.meshComponents.add(this.particlePool.meshComponents);
    this.createScene();

    this.components = {
      points: [],
      links: [],
      paths: []
    };

    this.pointSettings = {
      sizeMultiplier: 1,
      texture: this.loader.textures.point,
      color: "#00FFFF",
      opacity: 2,
      uniforms: {
        sizeMultiplier: {
          type: "f",
          value: 5
        },
        opacity: {
          type: "f",
          value: 2
        },
        texture: {
          type: "t",
          value: this.loader.textures.point
        }
      },
      attributes: {
        color: {
          type: "c",
          value: []
        },
        size: {
          type: "f",
          value: []
        }
      }
    };
    this.pointSettings.geometry = new THREE.Geometry(this.pointSettings.attributes);
    this.pointSettings.shaderMaterial = new THREE.ShaderMaterial({
      uniforms: this.pointSettings.uniforms,
      vertexShader: null,
      fragmentShader: null,
      blending: THREE.AdditiveBlending,
      transparent: true,
      depthTest: false
    });

    this.linkSettings = {
      opacity: 1,
      color: "#0099FF",
      nextPosition: 0,
      positions: [],
      indices: [],
      uniforms: {
        color: {
          type: "c",
          value: new THREE.Color("#0099FF")
        },
        opacityMultiplier: {
          type: "f",
          value: 1
        }
      },
      attributes: {
        opacity: {
          type: "f",
          value: []
        }
      }
    };
    this.linkSettings.geometry = new THREE.BufferGeometry(this.linkSettings.attributes);

    this.generatePoints();
    this.createLinks();

    this.pointSettings.shaderMaterial.vertexShader = this.loader.shaders.pointVertex;
    this.pointSettings.shaderMaterial.fragmentShader = this.loader.shaders.pointFragment;

    this.linkSettings.shaderMaterial.vertexShader = this.loader.shaders.linkVertex;
    this.linkSettings.shaderMaterial.fragmentShader = this.loader.shaders.linkFragment;

    this.initialized = true;
    this.run();
  }

  generatePoints() {
    const {
      points,
      radius
    } = this.settings;
    const half = points / 2;
    for (let index = 0; index < points; ++index) {
      let attempts = 0;
      while (++attempts < 100) {
        // https://stackoverflow.com/questions/5531827/random-point-on-a-given-sphere
        const u = Math.random();
        const v = Math.random();
        const r = (index % 3 === 0 ? 1 : Math.random()) * radius;
        const theta = 2 * Math.PI * u;
        const phi = Math.acos(2 * v - 1);
        const x = r * Math.sin(phi) * Math.cos(theta);
        const y = r * Math.sin(phi) * Math.sin(theta);
        const z = r * Math.cos(phi);
        const point = new Point(x, y, z);
        const closest = this.components.points.reduce((closest, other) => {
          const dist = point.distanceTo(other);
          if (dist < closest) {
            return dist;
          }
          return closest;
        }, 1000);
        if (closest >= 5) {
          this.components.points.push(point);
          this.pointSettings.geometry.vertices.push(point);
          this.pointSettings.attributes.color.value[index] = new THREE.Color("#00FFFF");
          this.pointSettings.attributes.size.value[index] = THREE.Math.randFloat(0.75, 3.0);
          break;
        }
      }
    }

    this.pointParticles = new THREE.Points(this.pointSettings.geometry, this.pointSettings.shaderMaterial);
    this.meshComponents.add(this.pointParticles);
    this.pointSettings.shaderMaterial.needsUpdate = true;
  }

  createLinks() {
    const {
      maxDist,
      maxConnections
    } = this.settings
    for (let index1 = 0; index1 < this.components.points.length; ++index1) {
      const point1 = this.components.points[index1];
      for (let index2 = 0; index2 < this.components.points.length; ++index2) {
        const point2 = this.components.points[index2];
        if (point1 !== point2 && point1.canConnect(point2, maxDist, maxConnections)) {
          const link = point1.connect(point2);
          this.constructLinkArrayBuffer(link);
        }
        if (point1.connections.length >= maxConnections) {
          break;
        }
      }
    }

    if (!this.renderer.getContext().getExtension("OES_element_index_uint")) {
      console.error("32bit index buffer not supported!");
    }

    const indices = new Uint32Array(this.linkSettings.indices);
    const positions = new Float32Array(this.linkSettings.positions);
    const opacities = new Float32Array(this.linkSettings.attributes.opacity.value);

    this.linkSettings.geometry.setIndex(new THREE.BufferAttribute(indices, 1));
    this.linkSettings.geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
    this.linkSettings.geometry.setAttribute("opacity", new THREE.BufferAttribute(opacities, 1));
    this.linkSettings.geometry.computeBoundingSphere();

    this.linkSettings.shaderMaterial = new THREE.ShaderMaterial({
      uniforms: this.linkSettings.uniforms,
      vertexShader: null,
      fragmentShader: null,
      blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true
    });

    this.linkMesh = new THREE.Line(this.linkSettings.geometry, this.linkSettings.shaderMaterial, THREE.LineSegments);
    this.meshComponents.add(this.linkMesh);
  }

  constructLinkArrayBuffer(link) {
    this.components.links.push(link);
    const vertices = link.vertices;

    for (let index = 0; index < vertices.length; ++index) {
      const vertex = vertices[index];
      this.linkSettings.positions.push(vertex.x, vertex.y, vertex.z);

      if (index < vertices.length - 1) {
        const idx = this.linkSettings.nextPosition;
        this.linkSettings.indices.push(idx, idx + 1);
        const opacity = THREE.Math.randFloat(0.005, 0.2);
        this.linkSettings.attributes.opacity.value.push(opacity, opacity);
      }
      ++this.linkSettings.nextPosition;
    }
  }

  createScene() {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let pixelRatio = window.devicePixelRatio || 1;
    let screenRatio = width / height;
    this.clock = new THREE.Clock();
    this.frameCount = 0;

    this.container = document.getElementById("scene-container");
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, screenRatio, 10, 5000);
    //this.cameraControl = new OrbitControls(this.camera, this.container);
    //this.cameraControl.update();

    this.camera_pivot = new THREE.Object3D()
    this.scene.add(this.camera_pivot);
    this.camera_pivot.add(this.camera);
    this.camera.position.set(100, 0, 0);
    this.camera.lookAt(this.camera_pivot.position);

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(pixelRatio);
    this.renderer.setClearColor("#0D0D0D", 1);
    this.renderer.autoClear = false;
    this.container.appendChild(this.renderer.domElement);

    this.scene.add(this.meshComponents);

    let debounce;
    window.onresize = () => {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        width = window.innerWidth;
        height = window.innerHeight;
        pixelRatio = window.devicePixelRatio || 1;
        screenRatio = width / height;

        this.camera.aspect = screenRatio;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
        this.renderer.setPixelRatio(pixelRatio);
      }, 250);
    };
  }

  run() {
    window.requestAnimationFrame(this.run.bind(this));
    this.renderer.clear();
    this.update();
    this.renderer.render(this.scene, this.camera);
    this.frameCount += 1;
  }

  update() {
    const currentTime = Date.now();
    const deltaTime = this.clock.getDelta();

    //this.cameraControl.update();
    this.camera_pivot.rotateOnAxis(new THREE.Vector3(0, 1, 0), 0.015 * deltaTime);

    for (let index = 0; index < this.components.points.length; ++index) {
      const point = this.components.points[index];
      if (this.components.paths.length < this.settings.currentMaxPaths - this.settings.maxConnections) {
        if (point.receivedPath && point.firedCount < 1) {
          point.fired = true;
          point.lastRelease = currentTime;
          point.releaseDelay = THREE.Math.randInt(100, 1000);
          this.releaseAt(point);
        }
      }
      point.receivedPath = false;
    }
    this.timeSinceLastRelease += deltaTime;
    if (this.timeSinceLastRelease >= 1) {
      this.timeSinceLastRelease = 0;
      if (this.components.paths.length < 5) {
        this.resetPoints();
      }
      this.releaseAt(this.components.points[THREE.Math.randInt(0, this.components.points.length)]);
    }

    for (let index = 0; index < this.components.paths.length; ++index) {
      const path = this.components.paths[index];
      path.travel(deltaTime);

      if (!path.alive) {
        path.particles.forEach((p) => p && p.free());
        this.components.paths.splice(index, 1);
        --index;
      }
    }

    this.particlePool.update();
  }

  resetPoints() {
    for (let index = 0; index < this.components.points.length; ++index) {
      this.components.points[index] && this.components.points[index].reset();
    }
  }

  releaseAt(point) {
    if (point) {
      const paths = point.createPath(this.particlePool, this.settings.minSpeed, this.settings.maxSpeed);
      for (let index = 0; index < paths.length; ++index) {
        this.components.paths.push(paths[index]);
      }
    }
  }

}