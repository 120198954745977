import * as THREE from "three";

function getControlPoint(point1, point2, cpLength) {
  const dirVec = new THREE.Vector3().copy(point2).sub(point1).normalize();
  const northPole = new THREE.Vector3(0, 0, 1);
  const axis = new THREE.Vector3().crossVectors(northPole, dirVec).normalize();
  const axisTheta = dirVec.angleTo(northPole);
  const rotMat = new THREE.Matrix4().makeRotationAxis(axis, axisTheta);

  const minz = Math.cos(THREE.Math.degToRad(45));
  const z = THREE.Math.randFloat(minz, 1);
  const theta = THREE.Math.randFloat(0, Math.PI * 2);
  const r = Math.sqrt(1 - z * z);
  const cpPos = new THREE.Vector3(r * Math.cos(theta), r * Math.sin(theta), z);
  cpPos.multiplyScalar(cpLength);
  cpPos.applyMatrix4(rotMat);
  cpPos.add(point1);
  return cpPos;
};

export default class Link extends THREE.CubicBezierCurve3 {

  constructor(point1, point2) {
    const cpLength = point1.distanceTo(point2) / THREE.Math.randFloat(1.5, 4.0);
    const controlPoint1 = getControlPoint(point1, point2, cpLength);
    const controlPoint2 = getControlPoint(point2, point1, cpLength);

    super(point1, controlPoint1, controlPoint2, point2);

    this.bezierSubdivision = 8;
    this.point1 = point1;
    this.point2 = point2;
    this.cpLength = cpLength;
    this.controlPoint1 = controlPoint1;
    this.controlPoint2 = controlPoint2;
    this.vertices = this.getSubdividedVertices();
  }

  getSubdividedVertices() {
    return this.getSpacedPoints(this.bezierSubdivision);
  };

}