
import Controller from './controller';
import React from 'react';
import "./App.css";
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
} from 'recharts';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCodeBranch, faUniversity, faCertificate, faCopy, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
const data = [
  {
    section: 'Front End', value: 80,
  },
  {
    section: 'Networking', value: 95,
  },
  {
    section: 'Back End', value: 100,
  },
  {
    section: 'Systems', value: 90,
  },
  {
    section: 'Tools', value: 80,
  },
  {
    section: 'Dev Ops', value: 70,
  },
  {
    section: 'Security', value: 90,
  },
];

const data2 = [
  {
    section: 'New Technologies', value: 100,
  },
  {
    section: 'Strategy', value: 100,
  },
  {
    section: 'Time Management', value: 90,
  },
  {
    section: 'Customer Service', value: 100,
  },
  {
    section: 'Mentoring', value: 80,
  },
  {
    section: 'Budgeting', value: 75,
  },
  {
    section: 'Communication', value: 80,
  },
];

window.app = new Controller();

function scrollTo(target) {
  //scrollToElement(target);
  const el = document.getElementsByClassName(target);
  el[0].scrollIntoView({ behavior: "smooth", block: "start" });
}

function onScroll(e) {
  const top = e.target.scrollTop;
  const el = document.getElementById("menu");
  const isFixed = el.className.indexOf("sticky") >= 0;
  let height = window.innerHeight - 60;
  if (window.innerWidth <= 800) {
    height += 60;
  }
  if (top >= height && !isFixed) {
    el.className = "menu sticky";
  }
  else if (top < height && isFixed) {
    el.className = "menu";
  }

  const sections = [
    document.getElementsByClassName("about")[0],
    document.getElementsByClassName("skills")[0],
    document.getElementsByClassName("work")[0],
    document.getElementsByClassName("contact")[0]
  ];
  const menus = [
    document.getElementById("menu-about"),
    document.getElementById("menu-skills"),
    document.getElementById("menu-work"),
    document.getElementById("menu-contact")
  ];
  const cutoff = window.innerHeight * 0.3;
  let active = 0;
  for (let i = 0; i < sections.length; ++i) {
    menus[i].className = "menu-item";
    const sectionTop = sections[i].offsetTop;
    if (sectionTop <= top + cutoff) {
      active = i;
    }
  }
  if (top + window.innerHeight > (e.target.scrollHeight - 40)) {
    active = sections.length - 1;
  }
  menus[active].className = "menu-item active";
}

function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  const e = document.getElementById("copy-tooltip");
  e.innerHTML = "Copied";
  setTimeout(() => {
    e.innerHTML = "Copy to clipboard";
  }, 2000);
};

export default function () {

  return (
    <React.Fragment>
      <div className="content" onScroll={onScroll}>
        <div className="header">
          <h1>Lucas Stufflebeam</h1>
          <h3>Software Architect</h3>
          <div className="header-buttons">
            <div className="button" role="button" onClick={e => scrollTo("about")}>Profile</div>
            <div className="button" role="button" onClick={e => scrollTo("skills")}>Skills</div>
            <div className="button" role="button" onClick={e => scrollTo("work")}>Work</div>
            <div className="button" role="button" onClick={e => scrollTo("contact")}>Contact</div>
          </div>
          <div className="scroll-arrow" onClick={e => scrollTo("about")}>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
        <div id="menu" className="menu">
          <div id="menu-about" className="menu-item" onClick={e => scrollTo("about")}>Profile</div>
          <div id="menu-skills" className="menu-item" onClick={e => scrollTo("skills")}>Skills</div>
          <div id="menu-work" className="menu-item" onClick={e => scrollTo("work")}>Work</div>
          <div id="menu-contact" className="menu-item" onClick={e => scrollTo("contact")}>Contact</div>
        </div>
        <div className="about section">
          <h2>Profile</h2>
          <div className="subtitle">I am a software architect and consultant.</div>
          <div className="columns">
            <div className="column">
              <div className="picture"></div>
            </div>
            <div className="column">
              <p><span className="label">Occupation:</span>Software Architect</p>
              <p><span className="label">Location:</span>Lakewood, CO</p>
              <p><span className="label">Hobbies:</span>Video games<br />Board games<br />Rock climbing<br />Delicous food</p>
            </div>
          </div>
          <div className="columns" style={{ display: "none" }}>
            <div className="column">
              <span className="label">My Story</span>
              <p>
                I first started programming in middle school when I checked out a book on Visual Basic from the library.
                I quickly started putting together small games, mainly clones of video games I enjoyed playing. I continued
                to improve my skills through highschool. I taught myself C++ and starting building 3d games using DirectX.
                I completed the International Baccalaureate program, earning the highest possible grade of 7 in computer
                sciences and a 6 in mathematics. I continued my education at Missouri State University and graduated in
                only 2 and half years with a bachlors degree in computer science and minors in math and physics.
              </p>
              <p>
                During my first semester of college I got a job a local game studio, Black Lantern Studios, as a junior game
                programmer. I quickly developed my skills over the next 2 and half years and left the studio as a senior gameplay
                programmer with 3 titles shipped as the lead developer. I then moved to Colorado and worked at several companies
                including Amadeus Consulting and Artemis Vision. There I toned by server development skills and worked on a wide
                variety of projects such as websites, servers, integrated systems, AI, electrical engineering, tools, and many others.
               </p>
              <p>
                I moved to California to continue my career in game development at PlayNext. I worked on mobile games as a senior
                server engineer. There I got my first taste of working on live games which came with an entirely new set of
                challenges I was excited to take on. I work on real-time network communcations, live support requests, server
                design and development, database design, and some front-end development.
               </p>
              <p>
                Growing tired of living in Silicon Valley, I moved back to Colorado where I worked as the lead network engineer at
                IdolMinds. I worked on globally distributed mobile games and navigated the complex world of real-time games over bad
                and inconsistant internet connections on mobile devices all across the globe. This included auto-scaling servers and
                peer-to-peer connections supporting up to 10,000 active users interacting with each other in the same game world.
              </p>
              <p>
                After leaving Idol Minds I worked for a while as a consultant on a wide variety of project until I was hired as
                the software architect at The Regis Company. I was tasked with bringing their 15 year old system into the modern
                age. I helped design and build an authoring system to streamline product development and built a server that can
                handle millions of users across the globe. This transition was done seamlessly on live products.
              </p>
            </div>
          </div>
        </div>
        <div className="skills section">
          <div className="skills-chart">
            <h2>Technical Skills</h2>
            <RadarChart cx={300} cy={250} outerRadius={150} width={600} height={600} data={data}>
              <PolarGrid />
              <PolarAngleAxis dataKey="section" />
              <Radar name="Skills" dataKey="value" stroke="#8884d8" fill="#2196f3" fillOpacity={0.6} />
            </RadarChart>
            <h2>Business Skills</h2>
            <RadarChart cx={300} cy={250} outerRadius={150} width={600} height={600} data={data2}>
              <PolarGrid />
              <PolarAngleAxis dataKey="section" />
              <Radar name="Skills" dataKey="value" stroke="#8884d8" fill="#2196f3" fillOpacity={0.6} />
            </RadarChart>
          </div>
          <div className="skills-table">
            <h2>Technical Skills</h2>
            <div className="score-container"><div className="label">Front End:</div><div className="value"><div className="score" style={{ width: "80%" }}></div></div></div>
            <div className="score-container"><div className="label">Networking:</div><div className="value"><div className="score" style={{ width: "95%" }}></div></div></div>
            <div className="score-container"><div className="label">Back End:</div><div className="value"><div className="score" style={{ width: "100%" }}></div></div></div>
            <div className="score-container"><div className="label">Systems:</div><div className="value"><div className="score" style={{ width: "90%" }}></div></div></div>
            <div className="score-container"><div className="label">Tools:</div><div className="value"><div className="score" style={{ width: "80%" }}></div></div></div>
            <div className="score-container"><div className="label">Dev Ops:</div><div className="value"><div className="score" style={{ width: "70%" }}></div></div></div>
            <div className="score-container"><div className="label">Security:</div><div className="value"><div className="score" style={{ width: "90%" }}></div></div></div>
            <h2>Business Skills</h2>
            <div className="score-container"><div className="label">New Technologies:</div><div className="value"><div className="score" style={{ width: "100%" }}></div></div></div>
            <div className="score-container"><div className="label">Strategy:</div><div className="value"><div className="score" style={{ width: "100%" }}></div></div></div>
            <div className="score-container"><div className="label">Time Management:</div><div className="value"><div className="score" style={{ width: "90%" }}></div></div></div>
            <div className="score-container"><div className="label">Customer Service:</div><div className="value"><div className="score" style={{ width: "100%" }}></div></div></div>
            <div className="score-container"><div className="label">Mentoring:</div><div className="value"><div className="score" style={{ width: "80%" }}></div></div></div>
            <div className="score-container"><div className="label">Budgeting:</div><div className="value"><div className="score" style={{ width: "75%" }}></div></div></div>
            <div className="score-container"><div className="label">Communication:</div><div className="value"><div className="score" style={{ width: "80%" }}></div></div></div>
          </div>
        </div>
        <div className="work section">
          <h2>Work Experience</h2>
          <VerticalTimeline layout="1-column">
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2014 - Present"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faCodeBranch} />}
            >
              <h3 className="vertical-timeline-element-title">Software Architect</h3>
              <h4 className="vertical-timeline-element-subtitle">The Regis Company - Golden, CO</h4>
              <ul>
                <li>Create, build, and maintain the company's technical strategy</li>
                <li>Design, build, and maintain a highly-available, global backend architecture that supports millions of users</li>
                <li>Help design and build an SaaS platform to streamline product development and reduce costs</li>
                <li>Seemlessly upgrade live products from 15 year old individual servers to the new SaaS infustructure</li>
                <li>Lead the development team by implementing coding quality, testing, and reusability standards</li>
                <li>Assist in managing and mentoring the development team</li>
                <li>Work directly with clients and ensure our technology meets their needs</li>
                <li>Handle Severity 1 customer support requests</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2014 - Present"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faCodeBranch} />}
            >
              <h3 className="vertical-timeline-element-title">Freelance Developer and Consultant</h3>
              <ul>
                <li>Develop and maintain custom software to meet the client's exact needs</li>
                <li>Handle on-site deployments of custom software in low-internet areas</li>
                <li>Help clients create and implement a modern technical strategy</li>
                <li>Help clients interview and hire a development team</li>
                <li>Help clients choose the best technology for their needs</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2014 - 2015"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faCodeBranch} />}
            >
              <h3 className="vertical-timeline-element-title">Lead Network Engineer</h3>
              <h4 className="vertical-timeline-element-subtitle">Idol Minds - Louisville, CO</h4>
              <ul>
                <li>Design, build, and maintain backend services for games</li>
                <li>Design, build, and maintain a real-time game network that can support up to 10,000 users interacting with the same game world</li>
                <li>Deal with the bad and inconsistent connectivity of mobile devices</li>
                <li>Design and implement a horizontal scaling and migration system to keep the number of servers to a minimum while ensuring that all users are in a well populated world</li>
                <li>Build Android, iOS, PS4, PC, and Xbox One games in Unity</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2012 - 2014"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faCodeBranch} />}
            >
              <h3 className="vertical-timeline-element-title">Senior Mobile Engineer</h3>
              <h4 className="vertical-timeline-element-subtitle">PlayNext - Santa Clara, CA</h4>
              <ul>
                <li>Assist in creating and maintaining large-scale backend services and databases to support mobiles games with 10,000+ daily active users</li>
                <li>Implement new features and enhance existing features in live mobile games using Unity and Unreal Engine 4</li>
                <li>Create rapid prototypes of mobile games and game features in Unreal Engine 4</li>
                <li>Create and maintain game management and support tools for live games</li>
                <li>Create and maintain KPI collection and monitoring tools</li>
                <li>Help implement and run large community events, both cooperative and competitive</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2011 - 2014"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faCodeBranch} />}
            >
              <h3 className="vertical-timeline-element-title">Senior Software Developer</h3>
              <h4 className="vertical-timeline-element-subtitle">Amadeus Consulting - Boulder, CO</h4>
              <ul>
                <li>Create fully custom software of all kinds (internal admin sites, pre-school registration, social media, mobile payment systems, summer camp registration, etc...)</li>
                <li>Create data mining system for processing very large databases</li>
                <li>Improve web crawler and database search speeds by 720% and 430% respectively</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2011 - 2012"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faCodeBranch} />}
            >
              <h3 className="vertical-timeline-element-title">Software Engineer</h3>
              <h4 className="vertical-timeline-element-subtitle">Artemis Vision - Denver, CO</h4>
              <ul>
                <li>Assist in designing and building components of factory robots</li>
                <li>Use machine vision and machine learning to "teach" robots how to detect and deal with faulty products in factories</li>
                <li>Use 3D imaging and deep learning techniques to allow factory robots to improve over time</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2009 - 2011"
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faCodeBranch} />}
            >
              <h3 className="vertical-timeline-element-title">Gameplay Programmer</h3>
              <h4 className="vertical-timeline-element-subtitle">Black Lantern Studios - Springfield, MO</h4>
              <ul>
                <li>Implement gameplay aspects of Nintendo DS, 3DS, Wii, PlayStation 3, and Xbox 360 games</li>
                <li>Assist in adding PlayStation 3, LeapPad, and Leapster support to an existing proprietery game engine</li>
                <li>Lead small development teams in multiple end-to-end development projects</li>
                <li>Greatly reduce the asset size and save data size to lower the cost of game cartridges without losing any data</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="2009 - 2011"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faUniversity} />}
            >
              <h3 className="vertical-timeline-element-title">Bachelor of Science in Computer Science</h3>
              <h4 className="vertical-timeline-element-subtitle">Missouri State University - Springfield, MO</h4>
              <ul>
                <li>Minors in Physics and Mathemetics</li>
                <li>Graduated in only 2 years</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              date="2005 - 2009"
              iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faUniversity} />}
            >
              <h3 className="vertical-timeline-element-title">International Baccalaureate Highschool Degree</h3>
              <h4 className="vertical-timeline-element-subtitle">Central High School - Springfield, MO</h4>
              <ul>
                <li>Received a max score of 7 in Computer Science, 99.9th percentile world wide</li>
                <li>Received a score of 6 in Mathematics, 97.5th percentile world wide</li>
              </ul>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--start"
              iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
              icon={<FontAwesomeIcon icon={faCertificate} />}
            >
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
        <div className="contact section">
          <h2>Contact</h2>

          <div style={{ textAlign: "left" }} >
            <a href="mailto:lucas@lucasstufflebeam.com">lucas@lucasstufflebeam.com</a>
            <div className="tooltip">
              <FontAwesomeIcon icon={faCopy} onClick={() => copyToClipboard("lucas@lucasstufflebeam.com")} />
              <span id="copy-tooltip" className="tooltiptext">Copy to clipboard</span>
            </div>
            <a style={{ display: "block", marginTop: "10px" }} href="https://www.linkedin.com/in/lucas-stufflebeam-55597546?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BBf8iBxAxQsmT%2BQUiSGMxKw%3D%3D" target="_blank"><FontAwesomeIcon style={{ marginLeft: "0px" }} icon={faLinkedin} /> LinkedIn</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
