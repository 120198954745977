import * as THREE from "three";

const shadersToLoad = [
  { name: "pointVertex", path: "shaders/point.vert" },
  { name: "pointFragment", path: "shaders/point.frag" },
  { name: "linkVertex", path: "shaders/link.vert" },
  { name: "linkFragment", path: "shaders/link.frag" }
];

export default class Loader {

  isLoaded = false;
  loadProgress = 0;
  shaders = {};
  objects = {};
  textures = {};

  load() {
    return new Promise((resolve, reject) => {
      const loadingManager = new THREE.LoadingManager();
      loadingManager.onLoad = () => {
        this.isLoaded = true;
        resolve();
      };
      loadingManager.onProgress = (item, loaded, total) => {
        this.loadProgress = loaded / total;
      };
      this.loadingManager = loadingManager;

      const shaderLoader = new THREE.FileLoader(loadingManager);
      shaderLoader.setResponseType("text");
      shadersToLoad.forEach((toLoad) => {
        shaderLoader.load(toLoad.path, (shader) => {
          this.shaders[toLoad.name] = shader;
        });
      });

      /*var OBJloader = new THREE.OBJLoader( loadingManager );
      OBJloader.load( 'models/brain_vertex_low.obj', function ( model ) {
        OBJ_MODELS.brain = model.children[ 0 ];
      } );*/

      const textureLoader = new THREE.TextureLoader(loadingManager);
      textureLoader.load("sprites/point.png", (texture) => {
        this.textures.point = texture;
      });
    });
  }
}