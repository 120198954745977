import * as THREE from "three";
import Link from "./link";
import Path from "./path";

export default class Point extends THREE.Vector3 {

  connections = [];
  receivedPath = false;
  lastRelease = 0;
  releaseDelay = 0;
  fired = false;
  firedCount = 0;
  previousRelease = null;

  canConnect(other, maxDist, maxConnections) {
    return other && this.distanceTo(other) < maxDist && this.connections.length < maxConnections && other.connections.length < maxConnections;
  }

  connect(other) {
    const link = new Link(this, other);
    this.connections.push(link);
    other.connections.push(link);
    return link;
  }

  createPath(particlePool, minSpeed, maxSpeed) {
    this.firedCount += 1;
    this.receivedPath = false;

    const paths = [];
    for (let index = 0; index < this.connections.length; ++index) {
      if (this.connections[index] !== this.previousRelease) {
        const path = new Path(particlePool, minSpeed, maxSpeed, this, this.connections[index]);
        if (path.particles && path.particles.length > 0) {
          paths.push(path);
        }
      }
    }
    return paths;
  }

  reset() {
    this.receivedPath = false;
    this.lastRelease = 0;
    this.releaseDelay = 0;
    this.fired = false;
    this.firedCount = 0;
  }
}
