import * as THREE from "three";
import Particle from "./particle";

export default class ParticlePool {

  constructor(scene, poolSize) {
    this.spriteTexture = scene.loader.textures.point;

    this.poolSize = poolSize * 8;
    this.pGeom = new THREE.Geometry();
    this.particles = this.pGeom.vertices;

    this.offScreenPos = new THREE.Vector3(9999, 9999, 9999);

    this.pColor = "#0096ff";
    this.pSize = 1;

    for (let index = 0; index < this.poolSize; ++index) {
      this.particles[index] = new Particle(this);
    }

    this.meshComponents = new THREE.Object3D();

    // inner particle
    this.pMat = new THREE.PointsMaterial({
      map: this.spriteTexture,
      size: this.pSize,
      color: this.pColor,
      blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true
    });

    this.pMesh = new THREE.Points(this.pGeom, this.pMat);
    this.pMesh.frustumCulled = false;

    this.meshComponents.add(this.pMesh);

    this.pMat_outer = this.pMat.clone();
    this.pMat_outer.size = this.pSize * 10;
    this.pMat_outer.opacity = 0.04;

    this.pMesh_outer = new THREE.Points(this.pGeom, this.pMat_outer);
    this.pMesh_outer.frustumCulled = false;

    this.meshComponents.add(this.pMesh_outer);
  }

  getAvgExecutionTime() {
    return this.profTime / this.itt;
  }

  getParticle() {
    for (let index = 0; index < this.poolSize; ++index) {
      const p = this.particles[index];
      if (p.available) {
        this.lastAvailableIdx = index;
        p.available = false;
        return p;
      }
    }
    return null;
  }

  update() {
    this.pGeom.verticesNeedUpdate = true;
  }

  updateSettings() {
    this.pMat.color.setStyle(this.pColor);
    this.pMat.size = this.pSize;
    this.pMat_outer.color.setStyle(this.pColor);
    this.pMat_outer.size = this.pSize * 10;
  }
}