import * as THREE from "three";

export default class Particle extends THREE.Vector3 {

  constructor(particlePool) {
    super(particlePool.offScreenPos.x, particlePool.offScreenPos.y, particlePool.offScreenPos.z);
    this.particlePool = particlePool;
    this.available = true;
  }

  free() {
    this.available = true;
    this.set(this.particlePool.offScreenPos.x, this.particlePool.offScreenPos.y, this.particlePool.offScreenPos.z);
  }
}